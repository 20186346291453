import _ from "lodash";
import splitParentChildValues from "./splitParentChildValues";

const checkExpandTableIndeterminateSelect = ({
  data,
  selection,
  keyField,
  childKeyField,
  childIdentificationKey
}) => {
  const { parentSelection, childSelection } = selection;
  const { parent, child } = splitParentChildValues(
    data,
    keyField,
    childKeyField,
    childIdentificationKey
  );
  const filteredParent = _.intersection(parentSelection, parent);
  const filteredChild = _.intersection(childSelection, child);
  const inParent = filteredParent.length > 0;
  const inChild = filteredChild.length > 0;
  const checkAllSelected =
    [...filteredParent, ...filteredChild].length === [...parent, ...child].length;

  return (inParent || inChild) && !checkAllSelected;
};

export default checkExpandTableIndeterminateSelect;
