import modifyAccessor from "./modifyAccessor";

const modifyComponentCell = columns =>
  columns.map(column => {
    const columnInfo = column;
    if (!column.Cell) {
      const Cell = props => {
        const {
          row: { _original }
        } = props;
        if (_original.expandTableIdentifier && column.hasOwnProperty("childCell"))
          return column.childCell(props);
        if (!_original.expandTableIdentifier && column.hasOwnProperty("parentCell"))
          return column.parentCell(props);
        const accessor = modifyAccessor(column, _original);
        if (accessor === undefined) {
          // eslint-disable-next-line no-console
          console.warn(`The accessor returns undefined in row ${column.id || column.accessor}`);
          return null;
        }
        return accessor;
      };
      columnInfo.Cell = Cell;
    }
    const cellClassName = (state, rowInfo, column) => {
      if (!rowInfo) return {};
      const {
        row: { _original }
      } = rowInfo;
      if (!_original.expandTableIdentifier) return {};
      if (columnInfo.hasOwnProperty("childCell") && _original.expandTableIdentifier)
        return { className: "custom-child-cell" };
      return { className: "wb-cell wb-cell-expand-cell" };
    };
    columnInfo.getProps = cellClassName;
    return columnInfo;
  });

export default modifyComponentCell;
