const combineParentAndChild = (tableData, childIdentifier, expandedList, keyField) => {
  let row = [];
  tableData.forEach(data => {
    const { [childIdentifier]: children, ...rest } = data;
    const child = (children || [])
      .map((childData, i) => ({
        ...childData,
        isFirstRow: i === 0,
        expandTableIdentifier: rest[keyField]
      }))
      .filter(childData => expandedList.includes(childData.expandTableIdentifier));
    row = [...row, { ...data }, ...child];
  });
  row = row.filter(Boolean);
  return row;
};

export default combineParentAndChild;
