import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { emptyStateDefaultMessage } from "../constant/message";

class EmptyState extends PureComponent {
  static propTypes = {
    category: PropTypes.string.isRequired,
    loadingMessage: PropTypes.object.isRequired,
    emptyColumnsMessage: PropTypes.object.isRequired,
    emptyDataMessage: PropTypes.object.isRequired,
    LoadingComponent: PropTypes.node,
    EmptyColumnsComponent: PropTypes.node,
    EmptyDataComponent: PropTypes.node
  };
  static defaultProps = {
    LoadingComponent: null,
    EmptyColumnsComponent: null,
    EmptyDataComponent: null
  };

  render() {
    const { category, ...categoryProps } = this.props;
    const capitalizeCategory = category.toUpperCase().charAt(0) + category.substring(1);
    const categoryComponentAlias = `${capitalizeCategory}Component`;
    const categoryMessageAlias = `${category}Message`;
    const defaultMessage = emptyStateDefaultMessage[categoryMessageAlias];
    const categoryMessage = { ...defaultMessage, ...categoryProps[categoryMessageAlias] };
    const categoryComponent = categoryProps[categoryComponentAlias];
    if (categoryComponent) return categoryComponent;
    const { heading, body } = categoryMessage;
    return (
      <div className="wb-es-container">
        <div className="wb-es-heading">{heading}</div>
        {body && <div className="wb-es-body">{body}</div>}
      </div>
    );
  }
}

export default EmptyState;
