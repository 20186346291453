import _ from "lodash";

const checkExpandTableParentIndeterminateSelect = ({
  row,
  selection,
  data,
  keyField,
  childKeyField,
  childIdentificationKey
}) => {
  const parent = data.find(data => data[keyField] === row[keyField]);
  if (!row.expandTableIdentifier && parent) {
    const children = parent[childIdentificationKey] || [];
    const childIds = children.map(child => child[childKeyField]);
    const { childSelection } = selection;
    const currentChildSelection = childSelection.filter(child => childIds.includes(child));
    const currentSelection = _.intersection(currentChildSelection, childIds);
    const foundInSelection = currentSelection.length > 0;
    const checkAllChildSelected = children.length !== currentSelection.length;
    return foundInSelection && checkAllChildSelected;
  }
  return false;
};

export default checkExpandTableParentIndeterminateSelect;
