import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as LeftIcon } from "../assets/chevron-left.svg";
import { ReactComponent as RightIcon } from "../assets/chevron-right.svg";
import { LEFT_PAGE, RIGHT_PAGE } from "../utils/constants";
import generatePageNumbers from "../utils/pagination/generatePageNumbers";
import findPageToJump from "../utils/pagination/findPageToJump";

class TablePagination extends PureComponent {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    pageNeighbours: PropTypes.oneOf([0, 1, 2]),
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    jumpOnMore: PropTypes.bool
  };

  static defaultProps = {
    pageNeighbours: 1,
    pageSize: 10,
    jumpOnMore: false
  };

  gotoPage = page => {
    const { onPageChange, totalRecords, pageSize } = this.props;
    const noOfPages = Math.ceil(totalRecords / pageSize);
    const currentPage = Math.max(0, Math.min(page, noOfPages));
    onPageChange(currentPage);
  };

  renderPages = () =>
    generatePageNumbers(this.props).map((page, index) => {
      const { currentPage, pageNeighbours, jumpOnMore } = this.props;
      const handleJump = () => this.gotoPage(findPageToJump(page, currentPage, pageNeighbours));

      if (page === LEFT_PAGE || page === RIGHT_PAGE)
        return (
          <li
            key={index}
            onClick={jumpOnMore ? handleJump : undefined}
            className={classNames("wb-pagination-item", {
              "wb-pagination-no-jump": !jumpOnMore
            })}
          >
            <span>...</span>
          </li>
        );

      return (
        <li
          onClick={handleJump}
          key={index}
          className={classNames("wb-pagination-item", {
            "wb-pagination-active": currentPage === page
          })}
        >
          <span className="wb-pagination-link">{page}</span>
        </li>
      );
    });

  render() {
    const { totalRecords, pageSize, currentPage } = this.props;
    const totalPages = Math.ceil(totalRecords / pageSize);

    if (!totalRecords) return null;
    if (totalPages <= 0) return null;

    const enableLeft = currentPage > 1;
    const enableRight = currentPage < totalPages;

    return (
      <div className="wb-pagination-container">
        <ul className="wb-pagination-list">
          <li
            key="left"
            onClick={enableLeft ? this.gotoPage.bind(null, currentPage - 1) : null}
            data-e2e-id="left"
            className={classNames("wb-pagination-item", "wb-pagination-nextPrevControl", {
              "wb-pagination-disabled": !enableLeft
            })}
          >
            <LeftIcon className="wb-pagination-icon" />
          </li>
          {this.renderPages()}
          <li
            key="right"
            data-e2e-id="right"
            onClick={enableRight ? this.gotoPage.bind(null, currentPage + 1) : null}
            className={classNames("wb-pagination-item", "wb-pagination-nextPrevControl", {
              "wb-pagination-disabled": !enableRight
            })}
          >
            <RightIcon className="wb-pagination-icon" />
          </li>
        </ul>
      </div>
    );
  }
}

export default TablePagination;
