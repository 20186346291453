import _ from "lodash";
import splitParentChildValues from "./splitParentChildValues";

const expandTableToggleAllSelection = ({
  data,
  keyField,
  selection,
  childKeyField,
  childIdentificationKey,
  onSelect
}) => {
  let newParentSelection = [];
  let newChildSelection = [];
  const { parentSelection, childSelection } = selection;
  const selectedValues = [].concat(...Object.values(selection));
  const { parent, child } = splitParentChildValues(
    data,
    keyField,
    childKeyField,
    childIdentificationKey
  );
  const currentSelection = [...parent, ...child];
  const filterData = currentSelection.filter(selected => selectedValues.includes(selected));
  const selectAll = filterData.length === currentSelection.length;

  if (!selectAll) {
    newParentSelection = [...new Set(parentSelection.concat(parent))].filter(Boolean);
    newChildSelection = [...new Set(childSelection.concat(child))].filter(Boolean);
  } else {
    newParentSelection = _.difference(parentSelection, parent);
    newChildSelection = _.difference(childSelection, child);
  }
  return onSelect({ parentSelection: newParentSelection, childSelection: newChildSelection });
};

export default expandTableToggleAllSelection;
