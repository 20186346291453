import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Icons from "../assets/icon";
import "../styles/BaseButton.scss";
import { BUTTON_TYPE } from "../constants/BaseButton";
import { SIZE, ICON_POS } from "../constants";

class BaseButton extends Component {
  static propTypes = {
    iconPos: PropTypes.oneOf(Object.values(ICON_POS)),
    buttonSize: PropTypes.oneOf(Object.values(SIZE)),
    iconName: PropTypes.string,
    buttonType: PropTypes.oneOf(Object.values(BUTTON_TYPE)),
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.element,
      PropTypes.object
    ]),
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    iconSize: PropTypes.oneOf([16, 20, 24])
  };

  static defaultProps = {
    iconName: "",
    iconPos: ICON_POS.LEFT,
    buttonSize: SIZE.REGULAR,
    buttonType: BUTTON_TYPE.DEFAULT,
    disabled: false,
    isActive: false,
    iconSize: 24
  };

  renderButtonIcon = () => {
    const { buttonSize, iconName, iconPos, buttonText, iconSize } = this.props;
    const size = buttonSize === "small" ? 20 : iconSize;
    if (Icons[iconName]) {
      const Icon = Icons[iconName];
      return (
        <span className={classNames("icon", { [iconPos]: !!buttonText })}>
          <Icon width={size} height={size} />
        </span>
      );
    }

    return null;
  };

  render() {
    const {
      iconPos,
      iconSize, // To prevent iconSize being passed to <button> DOM element
      buttonText,
      buttonSize,
      buttonType,
      iconName,
      isActive,
      className,
      disabled,
      ...rest
    } = this.props;

    return (
      <button
        className={classNames(className, buttonType, buttonSize, {
          active: isActive
        })}
        onClick={rest.onClick}
        disabled={disabled}
        {...rest}
      >
        <div
          className={classNames("buttonContainer", {
            iconButton: iconName && !buttonText,
            textButton: buttonText && !iconName
          })}
        >
          {buttonText && (
            <div
              className={classNames("text", {
                right: iconPos === "left",
                left: iconPos !== "left"
              })}
            >
              {buttonText}
            </div>
          )}
          {iconName && this.renderButtonIcon()}
        </div>
      </button>
    );
  }
}

export default BaseButton;
