import React from "react";
import TableHeader from "../../components/TableHeader";

const modifyColumns = (cols, defaultSortable, defaultCopyable) =>
  cols.map(c => {
    const { sortable = defaultSortable, copyable = defaultCopyable, Header } = c;
    return {
      copyable,
      ...c,
      Header: <TableHeader sortable={sortable} heading={Header} />
    };
  });

export default modifyColumns;
