import _ from "lodash";
import splitParentChildValues from "./splitParentChildValues";

const checkExpandTableSelectAll = ({
  data,
  selection,
  keyField,
  childKeyField,
  childIdentificationKey
}) => {
  const { parentSelection, childSelection } = selection;
  const { parent, child } = splitParentChildValues(
    data,
    keyField,
    childKeyField,
    childIdentificationKey
  );
  const parentLength = parent.length;
  const childLength = child.length;
  const filterInParentLength = _.intersection(parent, parentSelection).length;
  const filterInChildLength = _.intersection(child, childSelection).length;
  const inParent = filterInParentLength === parentLength && parentLength !== 0;
  const inChild = filterInChildLength === childLength && childLength !== 0;
  return inParent || inChild;
};

export default checkExpandTableSelectAll;
