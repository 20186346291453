import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ReactComponent as UnSortedIcon } from "../assets/sort.svg";
import { ReactComponent as AscendingIcon } from "../assets/sort-ascending.svg";
import { ReactComponent as DescendingIcon } from "../assets/sort-descending.svg";

class TableHeader extends PureComponent {
  static propTypes = {
    heading: PropTypes.node,
    sortable: PropTypes.bool
  };

  render() {
    const { heading, sortable } = this.props;
    if (!heading) {
      return null;
    }
    return (
      <div className="wb-table-header">
        <span className="wb-text">{heading}</span>
        <div className={classNames("wb-unSortedIcon", { "wb-hide": !sortable })}>
          <UnSortedIcon />
        </div>
        <div className={classNames("wb-ascSortedIcon", { "wb-hide": !sortable })}>
          <AscendingIcon />
        </div>
        <div className={classNames("wb-descSortedIcon", { "wb-hide": !sortable })}>
          <DescendingIcon />
        </div>
      </div>
    );
  }
}

export default TableHeader;
