import _ from "lodash";
import getParentAndChildIndexAndInfo from "./getParentAndChildIndexAndInfo";

const expandTableToggleSelection = ({
  key,
  data,
  selection,
  keyField,
  childKeyField,
  childIdentificationKey,
  onSelect
}) => {
  if (key) key = key.replace("select-", "");
  let parentSelection = [...selection.parentSelection];
  let childSelection = [...selection.childSelection];
  const { parentIndex, childIndex, childInfo } = getParentAndChildIndexAndInfo(
    data,
    keyField,
    childKeyField,
    childIdentificationKey,
    key
  );
  if (parentIndex >= 0) {
    parentSelection = [...new Set(_.xor(selection.parentSelection, [key]))];
    const currentChild = _.map(data[parentIndex][childIdentificationKey], childKeyField);

    childSelection = parentSelection.includes(key)
      ? [...childSelection, ...currentChild]
      : _.difference(childSelection, currentChild);
  }
  if (childIndex >= 0) {
    childSelection = [...new Set(_.xor(selection.childSelection, [key]))];
    const foundParent = data.find(da =>
      da[childIdentificationKey].map(child => child[childKeyField]).includes(childInfo)
    );
    const currentChildArray = foundParent[childIdentificationKey].map(
      child => child[childKeyField]
    );
    const checkEverythingIncludes = currentChildArray.every(child =>
      childSelection.includes(child)
    );
    parentSelection = !checkEverythingIncludes
      ? parentSelection.filter(parent => parent !== foundParent[keyField])
      : [...parentSelection, foundParent[keyField]];
  }
  parentSelection = parentSelection.filter(Boolean);
  childSelection = childSelection.filter(Boolean);
  return onSelect({ parentSelection, childSelection });
};

export default expandTableToggleSelection;
