import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import ReactTable, { ReactTableDefaults } from "react-table";
import classNames from "classnames";
import {
  toggleSelection,
  toggleAllSelection,
  checkSelectAll,
  checkIndeterminateSelect,
  modifyColumns,
  modifyClipboard
} from "../utils/table";
import { emptyStateDefaultMessage } from "../constant/message";
import { findEmptyStateCategory } from "../utils/common/findEmptyStateCategory";
import TableResizer from "./TableResizer";
import SelectTable from "./SelectTable";
import EmptyState from "./EmptyState";

// Create a global reference of noop so that we can use it later to check if prop wasn't passed.
const noop = () => {};

class Table extends PureComponent {
  static propTypes = {
    striped: PropTypes.bool,
    loading: PropTypes.bool,
    selectable: PropTypes.bool,
    data: PropTypes.array.isRequired,
    loadingMessage: PropTypes.object,
    emptyColumnsMessage: PropTypes.object,
    emptyDataMessage: PropTypes.object,
    LoadingComponent: PropTypes.node,
    EmptyColumnsComponent: PropTypes.node,
    EmptyDataComponent: PropTypes.node,
    columns: PropTypes.array.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
    selection: PropTypes.array,
    onSelect: PropTypes.func,
    keyField: PropTypes.string,
    expandedChildClassNames: PropTypes.string,
    selectedChildBackground: PropTypes.string,
    selectedChildClassNames: PropTypes.string,
    selectedBackground: PropTypes.string,
    selectedClassNames: PropTypes.string,
    onRowClick: PropTypes.func,
    onChildRowClick: PropTypes.func
  };

  static defaultProps = {
    striped: false,
    loading: false,
    selectable: false,
    sortable: true,
    copyable: true,
    loadingMessage: emptyStateDefaultMessage.loadingMessage,
    emptyColumnsMessage: emptyStateDefaultMessage.emptyColumnsMessage,
    emptyDataMessage: emptyStateDefaultMessage.emptyDataMessage,
    LoadingComponent: null,
    EmptyColumnsComponent: null,
    EmptyDataComponent: null,
    data: [],
    selection: [],
    columns: [],
    onSelect: noop,
    onRowClick: noop,
    onChildRowClick: noop,
    keyField: "id",
    expandedChildClassNames: "",
    selectedChildBackground: undefined,
    selectedChildClassNames: "",
    selectedBackground: undefined,
    selectedClassNames: "",
    expandedList: []
  };

  modifyClipboard = event => modifyClipboard(event, this.props);

  componentDidMount() {
    document.addEventListener("copy", this.modifyClipboard);
  }

  componentWillUnmount() {
    document.removeEventListener("copy", this.modifyClipboard);
  }

  render() {
    const {
      loading,
      striped,
      sortable,
      copyable,
      loadingMessage,
      emptyColumnsMessage,
      emptyDataMessage,
      LoadingComponent,
      EmptyColumnsComponent,
      selectable,
      selection,
      onSelect,
      keyField,
      EmptyDataComponent,
      data,
      columns,
      style,
      className,
      expandedChildClassNames,
      selectedChildBackground,
      selectedChildClassNames,
      selectedBackground,
      selectedClassNames,
      onRowClick,
      onChildRowClick
    } = this.props;

    const emptyStateCategory = findEmptyStateCategory(loading, columns, data);
    if (emptyStateCategory) {
      return (
        <EmptyState
          category={emptyStateCategory}
          loadingMessage={loadingMessage}
          emptyColumnsMessage={emptyColumnsMessage}
          emptyDataMessage={emptyDataMessage}
          LoadingComponent={LoadingComponent}
          EmptyColumnsComponent={EmptyColumnsComponent}
          EmptyDataComponent={EmptyDataComponent}
        />
      );
    }

    const modifiedColumns = modifyColumns(columns, sortable, copyable);
    // maxHeight will force the table body to overflow and scroll (since there won't be enough room),
    // hence giving the effect of a fixed table header row.
    // Also, this allows for the table to be neatly scrolled away when the content is over.
    const tableStyle = { maxHeight: `${window.screen.height - 200}px`, ...style };
    const tableClass = classNames(className, { "-striped": striped });
    const isSelected = value => _.includes(selection, value);

    const props = {
      ...this.props,
      showPagination: false,
      style: tableStyle,
      className: tableClass,
      pageSize: data.length,
      columns: modifiedColumns,
      ResizerComponent: TableResizer,
      getTrProps: (s, r) => {
        let selected = false;
        const validRow = r && r.original;
        if (!validRow) return {};
        const { original } = r;
        const checkSelection = row =>
          props.isSelected ? props.isSelected(row[keyField]) : isSelected(row[keyField]);
        if (original && original[keyField]) selected = checkSelection(r.original);
        const isChild = original.expandTableIdentifier;
        const isExpanded = r.original.id && props.expandedList.includes(r.original.id.toString());
        const className = classNames({
          "wb-row-hover":
            (!isChild && onRowClick !== noop) || (isChild && onChildRowClick !== noop),
          "wb-expand-row": isChild,
          "wb-expanded-parent": isExpanded,
          [expandedChildClassNames]: isChild,
          [selectedClassNames]: selected && !isChild,
          [selectedChildClassNames]: selected && isChild
        });
        const backgroundColor = isChild ? selectedChildBackground : selectedBackground;
        return {
          className,
          style: { backgroundColor: selected ? backgroundColor : "" },
          onClick: () => {
            isChild ? onChildRowClick(r) : onRowClick(r);
          }
        };
      }
    };
    if (selectable)
      return (
        <SelectTable
          isSelected={isSelected}
          selectAll={checkSelectAll(selection, data, keyField)}
          indeterminate={checkIndeterminateSelect(selection, data, keyField)}
          toggleSelection={key => toggleSelection(key, selection, onSelect)}
          toggleAll={_ => toggleAllSelection(selection, data, keyField, onSelect)}
          {...props}
        />
      );
    return (
      <ReactTable
        manual // Change to false if not using from server side data
        column={{
          ...ReactTableDefaults.column,
          className: "wb-cell",
          headerClassName: "wb-header"
        }}
        {...props}
        LoadingComponent={() => <div />}
      />
    );
  }
}

export default Table;
