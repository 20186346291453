const expandTableIsSelected = ({ key, selection, keyField, data }) => {
  const selected = false;
  const { parentSelection, childSelection } = selection;
  const isParent = data.some(d => d[keyField] === key);
  if (isParent) {
    const keyInParent = parentSelection.includes(key);
    if (keyInParent && isParent) return true;
    return selected;
  }
  const keyInChild = childSelection.includes(key);
  if (keyInChild && !isParent) return true;
  return selected;
};

export default expandTableIsSelected;
