const modifyAccessor = (column, _original) => {
  const { accessor, childAccessor, parentAccessor } = column;
  const accessorIsString = typeof accessor === "string";
  const parentAccessorIsString = typeof parentAccessor === "string";
  const childAccessorIsString = typeof childAccessor === "string";
  if (_original.expandTableIdentifier && childAccessor)
    return childAccessorIsString ? _original[childAccessor] : childAccessor(_original);
  if (!_original.expandTableIdentifier && parentAccessor)
    return parentAccessorIsString ? _original[parentAccessor] : parentAccessor(_original);
  if (accessor) return accessorIsString ? _original[accessor] : accessor(_original);

  return null;
};

export default modifyAccessor;
