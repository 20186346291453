import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ResizerIcon } from "../assets/adjust.svg";

class TableResizer extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <Fragment>
        <div className="wb-resizer-icon">
          <ResizerIcon />
        </div>
        <div className="wb-resizer" {...rest}>
          {children}
        </div>
      </Fragment>
    );
  }
}

export default TableResizer;
