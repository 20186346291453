const checkSelectAll = (selection, data, keyField) => {
  let selectAll = false;
  if (selection && data) {
    const dataIds = data.map(row => row[keyField]);
    const filterData = dataIds.filter(key => selection.includes(key));
    selectAll = filterData.length === dataIds.length;
  }
  return selectAll;
};

export default checkSelectAll;
