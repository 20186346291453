const emptyStateDefaultMessage = {
  loadingMessage: { heading: "Loading...", body: "Please wait while we fetch your data." },
  emptyColumnsMessage: { heading: "No Column Selected", body: "Select Columns to view the table." },
  emptyDataMessage: {
    heading: "No Data",
    body: "There seems to be no data for this filter or page."
  }
};

export { emptyStateDefaultMessage };
