import splitParentChildValues from "./splitParentChildValues";

const getParentAndChildIndexAndInfo = (
  data = [],
  keyField,
  childKeyField,
  childIdentificationKey,
  value
) => {
  const { parent, child } = splitParentChildValues(
    data,
    keyField,
    childKeyField,
    childIdentificationKey
  );
  const parentIndex = parent.indexOf(value);
  const childIndex = child.indexOf(value);
  const childInfo = child[childIndex];
  const parentInfo = parent[parentIndex];
  return { parentIndex, childIndex, parentInfo, childInfo };
};

export default getParentAndChildIndexAndInfo;
