const SIZE = {
  LARGE: "large",
  REGULAR: "regular",
  SMALL: "small"
};

const ICON_POS = {
  LEFT: "left",
  RIGHT: "right"
};

export { SIZE, ICON_POS };
