import modifyColumns from "./modifyColumns";

/* Modify clipboard content to be separated with tabs instead of new lines for each column,
so that they can be pasted beautifully on to an Excel sheet */
function modifyClipboard(event, props) {
  const { columns, copyable, sortable } = props;
  const modifiedColumns = modifyColumns(columns, sortable, copyable);
  const shouldIntercept =
    modifiedColumns &&
    modifiedColumns.filter(c => c.copyable).length > 0 &&
    ["rt-td", "rt-tr", "rt-th", "wb-header", "wb-cell", "wb-text"].some(
      c => event && event.target && event.target.className.indexOf(c) > -1
    );
  if (!shouldIntercept) {
    return; // Don't intercept data copied which does not originate from a Workbench Table
  }
  event.preventDefault();
  const selectionText = window.getSelection().toString();
  let formattedSelectionText = "";
  if (!!selectionText) {
    const columnCount = modifiedColumns.filter(c => c.copyable).length;
    const selectedParts = selectionText.split("\n");
    formattedSelectionText = selectedParts.reduce((fs, part, index) => {
      // Add new line at end of every row
      if ((index + 1) % columnCount === 0) fs += `${part}\n`;
      else fs += `${part}\t`;
      return fs;
    }, formattedSelectionText);
  }
  (event.clipboardData || window.clipboardData).setData("Text", formattedSelectionText);
}

export default modifyClipboard;
