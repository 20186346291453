import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactTable, { ReactTableDefaults } from "react-table";
import selectHOC from "react-table/lib/hoc/selectTable";
import modifyClipboard from "../utils/table/modifyClipboard";
import { emptyStateDefaultMessage } from "../constant/message";
import { findEmptyStateCategory } from "../utils/common/findEmptyStateCategory";
import EmptyState from "./EmptyState";
import TableCheckbox from "./TableCheckBox";
import TableResizer from "./TableResizer";

const CheckboxTable = selectHOC(ReactTable);

class SelectTable extends PureComponent {
  static propTypes = {
    striped: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    checkboxComponent: PropTypes.func,
    toggleSelection: PropTypes.func.isRequired,
    shouldHideSelect: PropTypes.func.isRequired,
    toggleAll: PropTypes.func.isRequired,
    selectAll: PropTypes.bool,
    indeterminate: PropTypes.bool,
    isSelected: PropTypes.func.isRequired,
    expandableIndeterminate: PropTypes.func,
    style: PropTypes.object,
    loadingMessage: PropTypes.object,
    emptyColumnsMessage: PropTypes.object,
    emptyDataMessage: PropTypes.object,
    LoadingComponent: PropTypes.node,
    EmptyColumnsComponent: PropTypes.node,
    EmptyDataComponent: PropTypes.node,
    selectedBackground: PropTypes.string,
    selectedChildBackground: PropTypes.string,
    selection: PropTypes.array,
    className: PropTypes.string
  };

  static defaultProps = {
    striped: false,
    loading: false,
    selectAll: false,
    indeterminate: false,
    expandableIndeterminate: () => false,
    shouldHideSelect: () => false,
    sortable: true,
    copyable: true,
    loadingMessage: emptyStateDefaultMessage.loadingMessage,
    emptyColumnsMessage: emptyStateDefaultMessage.emptyColumnsMessage,
    emptyDataMessage: emptyStateDefaultMessage.emptyDataMessage,
    LoadingComponent: null,
    EmptyColumnsComponent: null,
    EmptyDataComponent: null,
    keyField: "id",
    checkboxComponent: props => <TableCheckbox {...props} />,
    selectedBackground: "",
    selectedChildBackground: "",
    data: [],
    selection: [],
    columns: []
  };

  modifyClipboard = event => modifyClipboard(event, this.props);

  componentDidMount() {
    document.addEventListener("copy", this.modifyClipboard);
  }

  componentWillUnmount() {
    document.removeEventListener("copy", this.modifyClipboard);
  }

  render() {
    const {
      loading,
      toggleSelection,
      expandableIndeterminate,
      toggleAll,
      selectAll,
      indeterminate,
      isSelected,
      data,
      columns,
      checkboxComponent,
      loadingMessage,
      emptyColumnsMessage,
      emptyDataMessage,
      LoadingComponent,
      EmptyColumnsComponent,
      EmptyDataComponent,
      keyField,
      shouldHideSelect
    } = this.props;

    const emptyStateCategory = findEmptyStateCategory(loading, columns, data);
    if (emptyStateCategory) {
      return (
        <EmptyState
          category={emptyStateCategory}
          loadingMessage={loadingMessage}
          emptyColumnsMessage={emptyColumnsMessage}
          emptyDataMessage={emptyDataMessage}
          LoadingComponent={LoadingComponent}
          EmptyColumnsComponent={EmptyColumnsComponent}
          EmptyDataComponent={EmptyDataComponent}
        />
      );
    }

    const checkboxProps = {
      isSelected,
      toggleSelection,
      toggleAll,
      keyField,
      selectAll,
      selectType: "checkbox"
    };

    return (
      <CheckboxTable
        manual
        column={{
          ...ReactTableDefaults.column,
          className: "wb-cell wb-cell-checkbox",
          headerClassName: "wb-header wb-header-checkbox"
        }}
        SelectInputComponent={props =>
          checkboxComponent({
            ...props,
            shouldHideSelect,
            indeterminate: expandableIndeterminate(props.row)
          })
        }
        SelectAllInputComponent={props =>
          checkboxComponent({
            ...props,
            indeterminate
          })
        }
        {...this.props}
        {...checkboxProps}
        showPagination={false}
        ResizerComponent={TableResizer}
        LoadingComponent={() => <div />}
      />
    );
  }
}

export default SelectTable;
