import React from "react";
import { ReactComponent as UnSelected } from "../assets/checkbox-unselected.svg";
import { ReactComponent as IndeterminateSelected } from "../assets/checkbox-indeterminate.svg";
import { ReactComponent as Selected } from "../assets/checkbox-selected.svg";

const TableCheckbox = props => {
  if (props.shouldHideSelect(props)) return null;

  const onClick = e => {
    const { shiftKey } = e;
    e.stopPropagation();
    props.onClick(props.id, shiftKey, props.row);
  };

  let icon = <UnSelected onClick={e => onClick(e)} />;

  if (props.checked) {
    icon = <Selected onClick={e => onClick(e)} />;
  } else if (props.indeterminate) {
    icon = <IndeterminateSelected onClick={e => onClick(e)} />;
  }
  return <div className="wb-checkbox">{icon}</div>;
};

TableCheckbox.defaultProps = {
  showChildSelect: true,
  shouldHideSelect: () => false
};

export default TableCheckbox;
