import _ from "lodash";
import checkSelectAll from "./checkSelectAll";

const toggleAllSelection = (selection = [], data = [], keyField, onSelect) => {
  const selectAll = checkSelectAll(selection, data, keyField);
  let cloneSelection = [...selection];
  const currentDataValues = data.map(d => d[keyField]);

  if (!selectAll) {
    cloneSelection = [...cloneSelection, ...currentDataValues];
    cloneSelection = [...new Set(cloneSelection)];
  } else {
    cloneSelection = _.difference(cloneSelection, currentDataValues);
  }
  onSelect(cloneSelection);
};

export default toggleAllSelection;
