const BUTTON_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  CONSTRUCTIVE: "constructive",
  DESTRUCTIVE: "destructive",
  DEFAULT: "default",
  LINK: "link",
  CONSTRUCTIVE_SECONDARY: "constructive-secondary",
  DESTRUCTIVE_SECONDARY: "destructive-secondary"
};

export { BUTTON_TYPE };
