const splitParentChildValues = (data = [], keyField, childKeyField, childIdentificationKey) => {
  const parent = [];
  let child = [];
  data.forEach(d => {
    const parentInfo = d[keyField];
    parentInfo && parent.push(parentInfo);
    const childValues = (d[childIdentificationKey] || []).map(child => child[childKeyField]);
    child = [...child, ...childValues].filter(Boolean);
  });
  return { parent, child };
};

export default splitParentChildValues;
