import _ from "lodash";

const sortData = (data, sortArray, columns, childIdentificationKey) => {
  if (sortArray.length !== 0) {
    const accessors = [];
    const childAccessors = [];
    const accessorsSortMethod = [];
    sortArray.forEach(sortInfo => {
      const { id, desc } = sortInfo;
      const { accessor, childAccessor } = columns.find(
        column => column.id === id || column.accessor === id
      );
      const sortMethod = desc ? "desc" : "asc";
      accessors.push(accessor);
      childAccessors.push(childAccessor || accessor);
      accessorsSortMethod.push(sortMethod);
    });

    const sortedParent = _.orderBy(data, accessors, accessorsSortMethod);
    const combineSort = sortedParent.map(parent => {
      const childArray = parent[childIdentificationKey] || [];
      return {
        ...parent,
        [childIdentificationKey]: _.orderBy(childArray, childAccessors, accessorsSortMethod)
      };
    });
    return combineSort;
  }
  return data;
};

export default sortData;
