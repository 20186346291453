import React, { PureComponent } from "react";
import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ReactComponent as ArrowDown } from "../assets/chevron-down.svg";
import { ReactComponent as ArrowLeft } from "../assets/chevron-left-arrow.svg";

class ExpandCell extends PureComponent {
  static propTypes = {
    row: PropTypes.object.isRequired,
    childIdentificationKey: PropTypes.string,
    expandedList: PropTypes.array.isRequired,
    handleExpandClick: PropTypes.func.isRequired,
    expandChildComponent: PropTypes.func.isRequired
  };
  static defaultProps = {
    childIdentificationKey: ""
  };
  render() {
    const {
      row,
      expandedList,
      handleExpandClick,
      expandChildComponent,
      childIdentificationKey
    } = this.props;
    const isExpanded = expandedList.includes(row.id);
    const disabled = !row.expandTableIdentifier && _.isEmpty(row[childIdentificationKey]);
    const containerClass = classNames(
      "wb-cell-expand-cell-container",
      { disabled },
      {
        active: isExpanded
      }
    );

    if (row.expandTableIdentifier) {
      return <div className={containerClass}>{expandChildComponent(row)}</div>;
    }

    return (
      <div className={containerClass} onClick={e => !disabled && handleExpandClick(e)}>
        {isExpanded ? <ArrowDown /> : <ArrowLeft />}
      </div>
    );
  }
}

export default ExpandCell;
